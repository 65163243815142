import { Box, Button, Chip, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";

import { useStore } from "../../../../app/stores/store";


import { AddOutlined, ContentCopyOutlined } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "react-toastify";
import { LearningGoal } from "../../../../app/models/learningGoal";
import { router } from "../../../../app/router/Routes";


export default observer(function LearningGoalsSection() {

  const { nodeStore, accountStore } = useStore()
  const { selectedNode } = nodeStore
  const { user } = accountStore
  const [selectedGoalIdx, setSeleactedGoalIdx] = useState(-1);

  function onAddNewStatement() {
    if (!selectedGoal) return;
    router.navigate(`/nodes/${selectedNode.id}/learning-goals/new`);
  }

  const readonly = user?.organization.id !== selectedNode?.organizationId;

  const selectedGoal = selectedNode?.learningGoals[selectedGoalIdx];
  const displayGoals = (selectedGoal ? [selectedGoal] : selectedNode?.learningGoals ?? []).filter(goal => goal.learningGoalStatements?.length > 0);

  const isEmptyLearningGoals = displayGoals.flatMap(e => e.learningGoalStatements).length === 0;

  return (
    <Grid container direction={"column"}>
      <Grid item container xs={10} spacing={2}>
        <Grid item xs={4} md={3}>
          {selectedNode ? <LearningGoalsList
            goals={selectedNode.learningGoals}
            selectedGoal={selectedGoalIdx}
            setSeleactedGoal={setSeleactedGoalIdx}
          /> : <></>}
        </Grid>

        <Grid item xs={8} md={9} minHeight={0}>
          <Stack
            height="100%"
            bgcolor="#F9F9F9"
            borderRadius="12px"
            position="relative"
          >
            {

              (isEmptyLearningGoals) ?
                <Box
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <EmptyLearningGoalsSvg />

                  <Typography fontSize="16px">Fügen Sie jetzt Ihren ersten Lernzielsatz hinzu.</Typography>

                  <Button
                    variant="contained"
                    startIcon={<AddOutlined />}
                    onClick={onAddNewStatement}
                  >
                    Lernzielsatz hinzufügen
                  </Button>
                </Box>
                :
                <Box
                  overflow="auto"
                  position="absolute"
                  sx={{ inset: 0 }}
                >
                  {
                    displayGoals.map(goal => (
                      <Box
                        key={`goal_${goal.id}`}
                      >
                        <Typography fontWeight={400} fontSize={22} p={3}>
                          Lernziel {goal.competenceLevel.number} - {goal.competenceLevel.title}
                        </Typography>

                        <Box px={3}>
                          {
                            goal.learningGoalStatements.map((statement, index) => (
                              <>
                                {
                                  index !== 0 &&
                                  <Divider
                                    key={`goal_statement_divider_${statement.id}`}
                                    sx={{ my: 2 }}
                                  />
                                }

                                <Typography fontSize="14px">
                                  <div
                                    key={`goal_statement_${statement.id}`}
                                    dangerouslySetInnerHTML={{ __html: statement.content }}
                                  />
                                </Typography>
                              </>
                            ))
                          }
                        </Box>
                      </Box>
                    ))
                  }
                </Box>
            }
          </Stack>
        </Grid>
      </Grid>

    </Grid >);
})

function LearningGoalsList({ goals, selectedGoal, setSeleactedGoal }: {
  goals: LearningGoal[];
  selectedGoal: number;
  setSeleactedGoal: (value: number) => void;
}) {

  return (
    <Stack
      spacing={2}
    >
      {goals.map((step, index) => (
        <Box
          key={`step_${index}`}
          position="relative"
          onClick={() => setSeleactedGoal(index === selectedGoal ? -1 : index)}
          sx={{
            cursor: "pointer",
            ":hover": {
              bgcolor: "#F9F9F9",
            }
          }}
        >
          {
            index !== 0 &&
            <Box
              border="1px solid #483FA5"
              width="1px"
              position="absolute"
              top={-20}
              bottom={52}
              left={32}
              zIndex={1}
            />
          }

          {
            index !== goals.length - 1 &&
            <Box
              border="1px solid #483FA5"
              width="1px"
              position="absolute"
              top={52}
              bottom={-20}
              left={32}
              zIndex={1}
            />
          }

          <Stack
            direction="row"
            alignItems="center"

            border="1px solid #CED4DA"
            borderRadius="12px"
            p={2}
            spacing={2}
            position="relative"
          >
            <Chip
              color={selectedGoal === index ? "primary" : "default"}
              label={
                <Typography fontWeight={500}>{step.competenceLevel.number}</Typography>
              }
            />

            <Box flex={1}>
              <Typography color="#1D1B20" fontSize="16px">{step.competenceLevel.title}</Typography>
              <Box
                position="relative"
                width="100%"
                height="16px"
              >
                <Typography
                  position="absolute"
                  sx={{ inset: 0 }}
                  color="#1D1B20"
                  fontSize="14px"
                  noWrap
                >
                  {step.id}
                </Typography>
              </Box>
            </Box>

            <IconButton onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(step.id);
              toast.success("Copied", {
                autoClose: 1000
              });
            }}>
              <ContentCopyOutlined htmlColor="#483FA5" />
            </IconButton>
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}

function EmptyLearningGoalsSvg() {
  return (
    <svg width="270" height="190" viewBox="0 0 270 190" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_805_42282)">
        <path d="M220.097 125.767C220.399 125.375 220.611 124.921 220.719 124.438C220.827 123.955 220.827 123.454 220.721 122.97C220.614 122.487 220.402 122.033 220.101 121.64C219.8 121.247 219.416 120.925 218.977 120.697L219.646 112.987L215.06 111.438L214.267 122.338C213.933 123.101 213.889 123.961 214.146 124.754C214.402 125.547 214.94 126.218 215.659 126.641C216.377 127.064 217.225 127.208 218.043 127.047C218.861 126.886 219.591 126.431 220.096 125.767L220.097 125.767Z" fill="#FFB8B8" />
        <path d="M242.981 185.708H238.854L236.891 169.781H242.984L242.981 185.708Z" fill="#FFB8B8" />
        <path d="M235.904 184.527H243.867V189.541H230.891C230.891 188.211 231.419 186.936 232.359 185.996C233.299 185.056 234.574 184.527 235.904 184.527Z" fill="#2F2E41" />
        <path d="M233.608 175.139L229.599 176.124L223.891 161.128L229.808 159.674L233.608 175.139Z" fill="#FFB8B8" />
        <path d="M226.509 175.54L234.24 173.637L235.439 178.505L222.839 181.606C222.521 180.315 222.729 178.951 223.417 177.813C224.106 176.676 225.218 175.858 226.509 175.54Z" fill="#2F2E41" />
        <path d="M238.147 180.628C237.77 180.629 237.406 180.49 237.125 180.238C236.845 179.986 236.668 179.638 236.63 179.263L231.88 135.005L227.746 148.539L233.601 169.678C233.702 170.04 233.665 170.427 233.498 170.764C233.331 171.101 233.046 171.365 232.697 171.504L228.041 173.366C227.85 173.443 227.645 173.48 227.438 173.475C227.232 173.47 227.029 173.423 226.841 173.338C226.653 173.252 226.484 173.129 226.345 172.977C226.206 172.824 226.1 172.645 226.032 172.45L218.856 151.862C218.07 149.614 217.723 147.236 217.832 144.857L218.834 122.639L245.213 123.756L244.801 178.728C244.796 179.11 244.649 179.477 244.387 179.757C244.126 180.037 243.77 180.209 243.388 180.239L238.265 180.625C238.225 180.627 238.186 180.628 238.147 180.628Z" fill="#2F2E41" />
        <path d="M233.78 73.4668C238.679 73.4668 242.65 69.4955 242.65 64.5967C242.65 59.6978 238.679 55.7266 233.78 55.7266C228.881 55.7266 224.91 59.6978 224.91 64.5967C224.91 69.4955 228.881 73.4668 233.78 73.4668Z" fill="#FFB8B8" />
        <path d="M240.303 125.201C237.968 125.201 235.067 125.01 232.063 124.813C227.961 124.543 223.719 124.263 220.552 124.447C220.124 124.489 219.693 124.421 219.299 124.25C218.905 124.078 218.561 123.809 218.301 123.467C215.037 119.287 220.071 102.987 220.287 102.295L224.384 84.0689C225.287 80.0601 228.166 77.1031 231.901 76.3519C235.397 75.6498 238.778 77.0493 240.947 80.0988C241.062 80.2606 241.177 80.4266 241.292 80.5968C248.353 91.0545 244.359 117.036 244.091 118.712C244.464 119.159 246.481 121.685 245.927 123.44C245.701 124.154 245.089 124.64 244.106 124.884C242.854 125.132 241.578 125.238 240.302 125.2L240.303 125.201Z" fill="#6C63FF" />
        <path d="M220.693 125.21C219.89 125.21 218.806 124.913 217.345 124.307C216.717 124.046 214.806 123.251 217.126 102.973C218.266 93.013 219.898 83.1219 219.915 83.0233L219.93 82.928L220.018 82.8883C220.06 82.8691 224.23 80.9579 224.955 78.5018C224.979 78.4168 225.007 78.3343 225.04 78.2544C225.239 77.7084 225.642 77.2606 226.164 77.0043C226.685 76.7481 227.286 76.7033 227.84 76.8792C228.425 77.019 228.935 77.3763 229.267 77.8785C229.598 78.3808 229.726 78.9903 229.625 79.5834C229.372 81.1854 228.887 82.7418 228.183 84.2032C226.221 88.2516 224.892 98.9842 224.879 99.0922C224.804 100.009 223.023 121.726 222.68 123.374C222.56 123.949 222.34 124.654 221.693 124.995C221.383 125.148 221.04 125.223 220.694 125.212L220.693 125.21Z" fill="#2F2E41" />
        <path d="M230.386 124.377C230.224 124.377 230.066 124.323 229.939 124.224C227.329 122.241 231.552 98.203 234.056 89.9093C234.557 88.248 235.004 86.8262 235.383 85.6817C236.075 83.6281 236.557 81.51 236.824 79.3595C236.962 78.1945 237.261 77.5292 237.74 77.3252C238.073 77.1832 238.462 77.2797 238.903 77.6116C241.217 77.4974 243.465 78.4422 245.548 79.4827L247.423 80.418L249.527 117.67C249.468 120.047 244.781 121.726 242.738 121.833C239.455 122.005 233.561 122.54 230.964 124.184C230.794 124.302 230.594 124.369 230.386 124.377L230.386 124.377Z" fill="#2F2E41" />
        <path d="M218.66 117.388C218.629 117.388 218.598 117.388 218.567 117.385L214.317 117.124C214.105 117.111 213.899 117.054 213.71 116.957C213.522 116.86 213.356 116.725 213.222 116.56C213.089 116.395 212.992 116.204 212.936 115.999C212.881 115.795 212.868 115.581 212.9 115.371L214.823 102.63L218.443 84.7538C218.532 84.3134 218.713 83.8967 218.974 83.5307C219.234 83.1647 219.569 82.8575 219.956 82.629C220.342 82.4006 220.773 82.256 221.219 82.2046C221.666 82.1533 222.118 82.1962 222.547 82.3308C223.279 82.5646 223.904 83.0527 224.309 83.707C224.713 84.3612 224.871 85.1386 224.752 85.8986L221.876 103.811L220.173 116.071C220.121 116.436 219.939 116.769 219.662 117.011C219.384 117.253 219.029 117.387 218.66 117.388Z" fill="#2F2E41" />
        <path d="M233.18 103.442C233.439 103.02 233.787 102.66 234.201 102.388C234.614 102.115 235.082 101.937 235.572 101.865C236.062 101.793 236.561 101.829 237.035 101.971C237.51 102.113 237.947 102.357 238.317 102.686L245.285 99.3203L248.364 103.056L238.456 107.671C237.862 108.255 237.074 108.602 236.241 108.644C235.409 108.686 234.59 108.421 233.94 107.9C233.289 107.379 232.853 106.637 232.713 105.816C232.572 104.994 232.739 104.149 233.18 103.442Z" fill="#FFB8B8" />
        <path d="M242.861 107.058C242.518 107.058 242.184 106.942 241.915 106.73C241.645 106.518 241.455 106.221 241.375 105.887L240.364 101.68C240.286 101.363 240.313 101.031 240.439 100.73C240.565 100.43 240.784 100.178 241.064 100.012L247.168 96.3584L244.047 84.9339C243.777 83.9555 243.901 82.9104 244.392 82.0222C244.883 81.134 245.702 80.4733 246.675 80.1815C247.647 79.8898 248.694 79.9901 249.593 80.4611C250.492 80.9321 251.171 81.7363 251.484 82.7016L254.929 93.22C255.353 94.5022 255.356 95.8859 254.939 97.1701C254.522 98.4543 253.706 99.572 252.611 100.361L243.755 106.768C243.495 106.956 243.182 107.058 242.861 107.058Z" fill="#2F2E41" />
        <path d="M176.916 0.0195312H6.02391C4.42686 0.0214427 2.89576 0.65672 1.76647 1.78601C0.637183 2.9153 0.0019115 4.44639 0 6.04344V24.433C0.0019115 26.0301 0.637183 27.5612 1.76647 28.6904C2.89576 29.8197 4.42686 30.455 6.02391 30.4569H176.916C178.513 30.455 180.044 29.8197 181.174 28.6904C182.303 27.5612 182.938 26.0301 182.94 24.433V6.04344C182.938 4.44639 182.303 2.9153 181.174 1.78601C180.044 0.65672 178.513 0.0214427 176.916 0.0195312ZM182.306 24.433C182.304 25.8618 181.735 27.2315 180.725 28.2418C179.715 29.2521 178.345 29.8206 176.916 29.8227H6.02391C4.59511 29.8206 3.22543 29.2521 2.21511 28.2418C1.20479 27.2315 0.636273 25.8618 0.634171 24.433V6.04344C0.636273 4.61464 1.20479 3.24496 2.21511 2.23464C3.22543 1.22432 4.59511 0.655804 6.02391 0.653702H176.916C178.345 0.655804 179.715 1.22432 180.725 2.23464C181.735 3.24496 182.304 4.61464 182.306 6.04344V24.433Z" fill="#3F3D56" />
        <path d="M176.916 73.8941H6.02391C4.42686 73.8922 2.89576 73.2569 1.76647 72.1276C0.637183 70.9983 0.0019115 69.4672 0 67.8701V49.481C0.0019115 47.8839 0.637183 46.3528 1.76647 45.2235C2.89576 44.0942 4.42686 43.4589 6.02391 43.457H176.916C178.513 43.4589 180.044 44.0942 181.174 45.2235C182.303 46.3528 182.938 47.8839 182.94 49.481V67.8701C182.938 69.4672 182.303 70.9983 181.174 72.1276C180.044 73.2569 178.513 73.8922 176.916 73.8941ZM6.02391 44.0912C4.59496 44.0928 3.22501 44.6612 2.21459 45.6716C1.20417 46.682 0.635796 48.052 0.634171 49.481V67.8701C0.635509 69.2993 1.20375 70.6695 2.2142 71.6802C3.22465 72.6908 4.59478 73.2593 6.02391 73.261H176.916C178.345 73.2594 179.715 72.6911 180.726 71.6807C181.736 70.6703 182.305 69.3002 182.306 67.8712V49.481C182.305 48.052 181.736 46.682 180.726 45.6716C179.715 44.6612 178.345 44.0928 176.916 44.0912H6.02391Z" fill="#CCCCCC" />
        <path d="M176.916 117.33H6.02391C4.42686 117.328 2.89576 116.692 1.76647 115.563C0.637183 114.434 0.0019115 112.903 0 111.306V92.9165C0.0019115 91.3194 0.637183 89.7883 1.76647 88.6591C2.89576 87.5298 4.42686 86.8945 6.02391 86.8926H176.916C178.513 86.8945 180.044 87.5298 181.174 88.6591C182.303 89.7883 182.938 91.3194 182.94 92.9165V111.306C182.938 112.903 182.303 114.434 181.174 115.563C180.044 116.692 178.513 117.328 176.916 117.33ZM6.02391 87.5253C4.59496 87.5269 3.22501 88.0953 2.21459 89.1057C1.20417 90.1161 0.635796 91.4861 0.634171 92.915V111.304C0.635701 112.733 1.20403 114.103 2.21446 115.114C3.22489 116.124 4.5949 116.693 6.02391 116.694H176.916C178.345 116.693 179.715 116.124 180.726 115.114C181.736 114.104 182.305 112.734 182.306 111.305V92.9165C182.305 91.4872 181.737 90.1169 180.726 89.1061C179.716 88.0954 178.345 87.5268 176.916 87.5253H6.02391Z" fill="#CCCCCC" />
        <path d="M29.1176 25.7235C27.0439 25.7235 25.0168 25.1086 23.2926 23.9565C21.5684 22.8044 20.2245 21.1669 19.4309 19.2511C18.6374 17.3352 18.4297 15.2271 18.8343 13.1932C19.2388 11.1594 20.2374 9.29116 21.7037 7.82484C23.1701 6.35851 25.0383 5.35993 27.0721 4.95537C29.106 4.55082 31.2141 4.75845 33.13 5.55202C35.0458 6.34559 36.6833 7.68945 37.8354 9.41366C38.9875 11.1379 39.6024 13.165 39.6024 15.2387C39.6024 18.0194 38.4978 20.6863 36.5315 22.6526C34.5652 24.6189 31.8983 25.7235 29.1176 25.7235Z" fill="#6C63FF" />
        <path d="M161.436 23.7976H49.517C48.8443 23.7976 48.1991 23.5304 47.7234 23.0547C47.2477 22.579 46.9805 21.9338 46.9805 21.2611C46.9805 20.5884 47.2477 19.9432 47.7234 19.4675C48.1991 18.9918 48.8443 18.7246 49.517 18.7246H161.436C162.109 18.7246 162.754 18.9918 163.23 19.4675C163.705 19.9432 163.973 20.5884 163.973 21.2611C163.973 21.9338 163.705 22.579 163.23 23.0547C162.754 23.5304 162.109 23.7976 161.436 23.7976Z" fill="#CCCCCC" />
        <path d="M124.972 13.0183H49.5131C48.8403 13.0183 48.1952 12.7511 47.7195 12.2754C47.2438 11.7997 46.9766 11.1545 46.9766 10.4818C46.9766 9.8091 47.2438 9.16392 47.7195 8.68824C48.1952 8.21255 48.8403 7.94531 49.5131 7.94531H124.972C125.644 7.94531 126.29 8.21255 126.765 8.68824C127.241 9.16392 127.508 9.8091 127.508 10.4818C127.508 11.1545 127.241 11.7997 126.765 12.2754C126.29 12.7511 125.644 13.0183 124.972 13.0183Z" fill="#CCCCCC" />
        <path d="M29.1176 69.159C27.0439 69.159 25.0168 68.5441 23.2926 67.392C21.5684 66.2399 20.2245 64.6025 19.4309 62.6866C18.6374 60.7708 18.4297 58.6626 18.8343 56.6288C19.2388 54.5949 20.2374 52.7267 21.7037 51.2604C23.1701 49.7941 25.0383 48.7955 27.0721 48.3909C29.106 47.9864 31.2141 48.194 33.13 48.9876C35.0458 49.7811 36.6833 51.125 37.8354 52.8492C38.9875 54.5734 39.6024 56.6005 39.6024 58.6742C39.6024 61.455 38.4978 64.1218 36.5315 66.0881C34.5652 68.0544 31.8983 69.159 29.1176 69.159Z" fill="#E6E6E6" />
        <path d="M161.436 67.2351H49.517C48.8443 67.2351 48.1991 66.9679 47.7234 66.4922C47.2477 66.0165 46.9805 65.3713 46.9805 64.6986C46.9805 64.0259 47.2477 63.3807 47.7234 62.905C48.1991 62.4293 48.8443 62.1621 49.517 62.1621H161.436C162.109 62.1621 162.754 62.4293 163.23 62.905C163.705 63.3807 163.973 64.0259 163.973 64.6986C163.973 65.3713 163.705 66.0165 163.23 66.4922C162.754 66.9679 162.109 67.2351 161.436 67.2351Z" fill="#E6E6E6" />
        <path d="M124.972 56.4558H49.5131C48.8403 56.4558 48.1952 56.1886 47.7195 55.7129C47.2438 55.2372 46.9766 54.592 46.9766 53.9193C46.9766 53.2466 47.2438 52.6014 47.7195 52.1257C48.1952 51.65 48.8403 51.3828 49.5131 51.3828H124.972C125.644 51.3828 126.29 51.65 126.765 52.1257C127.241 52.6014 127.508 53.2466 127.508 53.9193C127.508 54.592 127.241 55.2372 126.765 55.7129C126.29 56.1886 125.644 56.4558 124.972 56.4558Z" fill="#E6E6E6" />
        <path d="M29.1176 112.595C27.0439 112.595 25.0168 111.98 23.2926 110.828C21.5684 109.676 20.2245 108.038 19.4309 106.122C18.6374 104.206 18.4297 102.098 18.8343 100.064C19.2388 98.0305 20.2374 96.1623 21.7037 94.6959C23.1701 93.2296 25.0383 92.231 27.0721 91.8265C29.106 91.4219 31.2141 91.6295 33.13 92.4231C35.0458 93.2167 36.6833 94.5605 37.8354 96.2848C38.9875 98.009 39.6024 100.036 39.6024 102.11C39.6024 104.891 38.4978 107.557 36.5315 109.524C34.5652 111.49 31.8983 112.595 29.1176 112.595Z" fill="#E6E6E6" />
        <path d="M161.436 110.671H49.517C48.8443 110.671 48.1991 110.403 47.7234 109.928C47.2477 109.452 46.9805 108.807 46.9805 108.134C46.9805 107.461 47.2477 106.816 47.7234 106.341C48.1991 105.865 48.8443 105.598 49.517 105.598H161.436C162.109 105.598 162.754 105.865 163.23 106.341C163.705 106.816 163.973 107.461 163.973 108.134C163.973 108.807 163.705 109.452 163.23 109.928C162.754 110.403 162.109 110.671 161.436 110.671Z" fill="#E6E6E6" />
        <path d="M124.972 99.8914H49.5131C48.8403 99.8914 48.1952 99.6241 47.7195 99.1485C47.2438 98.6728 46.9766 98.0276 46.9766 97.3549C46.9766 96.6821 47.2438 96.037 47.7195 95.5613C48.1952 95.0856 48.8403 94.8184 49.5131 94.8184H124.972C125.644 94.8184 126.29 95.0856 126.765 95.5613C127.241 96.037 127.508 96.6821 127.508 97.3549C127.508 98.0276 127.241 98.6728 126.765 99.1485C126.29 99.6241 125.644 99.8914 124.972 99.8914Z" fill="#E6E6E6" />
        <path d="M28.5821 18.9253C28.3217 18.9259 28.0682 18.8417 27.8598 18.6855L27.8468 18.6758L25.1292 16.597C25.0033 16.5005 24.8977 16.3802 24.8183 16.2429C24.739 16.1056 24.6874 15.954 24.6667 15.7967C24.6459 15.6395 24.6563 15.4797 24.6973 15.3265C24.7382 15.1733 24.809 15.0296 24.9055 14.9038C25.002 14.7779 25.1223 14.6723 25.2596 14.5929C25.3969 14.5135 25.5485 14.462 25.7058 14.4412C25.863 14.4204 26.0228 14.4308 26.176 14.4718C26.3292 14.5128 26.4728 14.5835 26.5987 14.68L28.3589 16.03L32.5183 10.6034C32.7131 10.3494 33.0008 10.1832 33.3181 10.1413C33.6355 10.0993 33.9565 10.1852 34.2106 10.3799L34.1849 10.416L34.2113 10.3799C34.465 10.575 34.631 10.8628 34.6729 11.1801C34.7147 11.4974 34.629 11.8184 34.4345 12.0726L29.542 18.4526C29.4287 18.5993 29.2831 18.718 29.1165 18.7994C28.9499 18.8808 28.7668 18.9228 28.5814 18.9221L28.5821 18.9253Z" fill="white" />
        <path d="M269.642 189.982H198.135C198.039 189.982 197.947 189.944 197.879 189.876C197.811 189.809 197.773 189.717 197.773 189.621C197.773 189.525 197.811 189.433 197.879 189.366C197.947 189.298 198.039 189.26 198.135 189.26H269.642C269.737 189.26 269.829 189.298 269.897 189.366C269.965 189.433 270.003 189.525 270.003 189.621C270.003 189.717 269.965 189.809 269.897 189.876C269.829 189.944 269.737 189.982 269.642 189.982Z" fill="#CCCCCC" />
        <path d="M237.041 66.9609C237.041 66.9609 235.961 60.9926 233.505 61.7057C231.049 62.4187 224.728 63.1483 224.716 60.3376C224.704 57.5268 230.666 55.0424 234.181 55.3786C237.696 55.7149 243.673 56.7436 243.344 62.0152C243.08 65.3353 242.054 68.5498 240.345 71.4085L237.041 66.9609Z" fill="#2F2E41" />
      </g>
      <defs>
        <clipPath id="clip0_805_42282">
          <rect width="270" height="189.963" fill="white" transform="translate(0 0.0195312)" />
        </clipPath>
      </defs>
    </svg>
  )
}
