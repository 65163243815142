import { MoreHoriz, Share } from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Board } from "../../app/models/board";
import { router } from "../../app/router/Routes";

export default observer(function RelavantBoards({ boards }: { boards: Board[] }) {
    boards ??= [];

    return (
        <>
            {boards.slice(0, 2).map((board) => (
                <RelavantBoardChip
                    key={`relavant_boards_${board.id}`}
                    board={board}
                />
            ))}

            {
                boards.length > 2 &&
                <MoreBoards boards={boards} />
            }
        </>
    )
});

const RelavantBoardChip = observer(({ board }: { board: Board }) => {
    return (
        <Button
            size="small"
            sx={{
                backgroundColor: "#E1E0FF",
                color: "#483FA5",
                boxShadow: "none",
                ":hover": {
                    backgroundColor: "#BFBBF3",
                    boxShadow: "none",
                },
                py: "5px",
                borderRadius: "6px",
            }}
            startIcon={<Share />}
            variant="contained"
            onClick={() => router.navigate(`/graphEditor/${board.id}`)}
        >
            <Typography
                fontSize="smaller"
                fontWeight={600}
                maxWidth="100px"
                noWrap
            >
                {board.title}
            </Typography>
        </Button>
    )
})

const MoreBoards = observer((props: { boards: Board[] }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size="small"
                color="primary"
                onClick={handleClick}
                sx={{
                    backgroundColor: "#E1E0FF",
                    color: "#483FA5",
                    boxShadow: "none",
                    ":hover": {
                        backgroundColor: "#BFBBF3",
                        boxShadow: "none",
                    },
                    py: "5px",
                    borderRadius: "6px",
                }}
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                onClose={handleClose}
            >
                {props.boards?.map((board) => (
                    <MenuItem
                        key={`more_boards_${board.id}`}
                        onClick={() => {
                            router.navigate(`/graphEditor/${board.id}`);
                            handleClose();
                        }}
                    >
                        {board.title.shorten(30)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
)
